<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>本地商务服务</span>
                </div> 
                <div class="nav-list">
                    <div v-for="(item,index) in fenleiList" :key="index">
                        <div class="nav-item"  @click="tabClick(item.id)" v-if="item.cs.length==0">
                            <div :class="{active:id==item.id}">{{item.name}}</div>
                            <!-- <p><i class="el-icon-arrow-right"></i></p> -->
                        </div>

                        <div v-if="item.cs.length>0">
                            <div  class="nav-item"  @click="tabClick1(item,index)">
                                <div :class="{active:id==item.id}">{{item.name}}</div>
                                <p>
                                    <i class="el-icon-arrow-right"  v-if="!item.isList"></i>
                                    <i class="el-icon-arrow-down"  v-if="item.isList"></i>
                                </p>
                            </div>
                            <template v-if="item.isList">
                                <div class="nav-item1" v-for="(item1,index1) in item.cs" :key="index1" @click="tabClick(item1.id)" >
                                    <div :class="{active:id==item1.id}">{{item1.name}}</div>
                                    <!-- <p><i class="el-icon-arrow-right"></i></p> -->
                                </div>
                            </template>                            
                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/bdswfw'}">本地商务服务</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item>咨询服务</el-breadcrumb-item> -->
                        <!-- <el-breadcrumb-item>{{title}}</el-breadcrumb-item> -->
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="fuwu-list">
                        <div class="fuwu-item" v-for="(item,index) in liebiaoList" :key="index">
                            <div class="fuwu-item-text">{{item.name}}</div>
                            <div class="fuwu-item-p" v-html="delLabel(item.content)">进口需求子分类介绍进口需求子分类介绍进口需求子分类介绍</div>
                            <div class="fuwu-item-bt" @click="goTo('shangwu_details',{id:item.id})">服务内容介绍</div>
                        </div>
                    </div>
                    <div class="paginationBox">
                        <el-pagination 
                        @current-change="handleCurrentChange" 
                        :current-page.sync="queryInfo.page"
                        :page-size="queryInfo.nate" 
                        :page-sizes="[1, 2, 5, 10]" 
                        layout="total, prev, pager, next ,jumper" 
                        :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import { getplates,getplatexts } from "../../api/index"
    export default {
        data() {
            return {
                id:'',
                fenleiList:[],
                liebiaoList:[],

                // 总条数
				total: 0,
				// 分页参数
				queryInfo: {
					page: 1,
                    nate: 20,
				},
            }
        },
        created() {
            this.id=this.$route.query.id 
            this.getplates() 
            this.getplatexts()          
        },
        methods: {    
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            tabClick(id){
                this.id=id;
                this.getplatexts();
            },
            tabClick1(item,index){
                this.id=item.id;
                this.getplatexts();
                this.fenleiList[index].isList=!item.isList


            },
            // 获取分类
            getplates(){
                getplates({
                    p_id:25
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        // this.fenleiList=res.data
                        let list = res.data || [] 
                         //是否不能在选择点击
                        res.data.forEach((id,index) => {
                           list[index]['isList'] = false              
                        })
                        this.fenleiList = list
                        console.log(this.fenleiList);
                    }
                })
            },
            handleCurrentChange() {
				this.getplatexts()
				
			},
            //获取列表
            getplatexts(){
                getplatexts({
                    ...this.queryInfo,
                    plate_id:this.id

                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.total = res.data.total
                        this.liebiaoList=res.data.data
                    }
                })
            } 
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    // &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}
                .nav-item1{cursor: pointer;                
                    height: 50px;padding: 0 20px;padding-left:30px;
                    display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    // &:last-of-type{border-bottom: none;}
                }

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 30px 20px;
                .paginationBox{
                    display: flex;
                    justify-content: center;
                    padding-bottom: 20px;
                }
                .fuwu-list{
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 30px;
                    .fuwu-item{
                        width: 18.5%;
                        margin-right: 1.875%;
                        margin-bottom: 30px;
                        border: 1px solid #E8E8E8;
                        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
                        padding:  0 19px;
                        box-sizing: border-box;
                        text-align: center;
                        &:nth-child(5n+0){
                            margin-right: 0;
                        }
                        .fuwu-item-text{
                            color: #000;
                            font-size: 16px;
                            font-weight: bold;
                            margin-top: 25px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                        .fuwu-item-p{
                            height: 40px;
                            color: #666;
                            font-size: 14px;
                            line-height: 20px;
                            margin: 15px 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        .fuwu-item-bt{
                            width: 80%;
                            height: 33px;
                            border-radius: 15px;
                            background: linear-gradient(138deg, #CF694E, #FD9976);
                            margin: auto;
                            margin-bottom: 13px;
                            text-align: center;
                            line-height: 33px;
                            color: #fff;
                            font-size: 14px;
                            cursor: pointer;
                        }

                    }
                }
            }
        }
        
    }

</style>